export const GENERIC_ERROR_MSG = "Oops! Something went wrong...";

export const NUMBER_OF_UPCOMING_GAMES_TO_SHOW = 6;

export const TBD_STRING = "To be determined";

export const IFNCS_STRING = "** If necessary **";

export const RESCHEDULED_STRING = "Postponed match";

export const EMPTY_PLAYOFF_TEAM_NAME = "-";

export const FINALS_GAME_IDS = [93, 177, 258, 394, 453, 524];

export const THIRD_PLACE_GAME_IDS = [92, 176, 257, 393, 452, 523];

export const SHORT_NUMBER_OF_PLAYOFF_TEAMS = 6;

export const REGULAR_NUMBER_OF_PLAYOFF_TEAMS = 8;

export const ROUND_NAMES = {
  1: "Quarter finals",
  2: "Semi finals",
  3: "Final",
  4: "3rd Place",
};

export const topPerformanceCategories = {
  PTS: "Points",
  REB: "Rebounds",
  AST: "Assists",
  STL: "Steals",
  BLK: "Blocks",
  THREE_PM: "Three pointers",
};

export const STATS_TABLE_HEADERS = [
  "#",
  "Season",
  "Player",
  "Team",
  "Matchup",
  "Date",
  "GP",
  "MP",
  "PTS",
  "REB",
  "AST",
  "STL",
  "BLK",
  "FGM",
  "FGA",
  "FG%",
  "3PM",
  "3PA",
  "3PT%",
  "FTM",
  "FTA",
  "FT%",
  "DRB",
  "ORB",
  "TO",
  "PF",
  "+/-",
];

export const ALL_STARS_STATS_TABLE_HEADERS = [
  "Vote",
  "Player",
  "GP",
  "MP",
  "PTS",
  "REB",
  "AST",
  "STL",
  "BLK",
  "FG%",
  "3PT%",
  "FT%",
  "ORB",
  "TO",
];

export const STATS_TABLE_HEADERS_WIDTH = [
  2.5,
  10,
  20,
  18,
  18,
  16,
  3,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  5,
  4,
  4,
  5,
  4,
  4,
  5,
  3.5,
  3.5,
  3,
  3,
  4,
];


export const ALL_STARS_STATS_TABLE_HEADERS_WIDTH = [
  6,
  16,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  5.5,
  5.5,
  5.5,
  5,
  5,
];

export const ERROR_PAGE_404_PATHNAME = "/error404";

export const PLAYOFFS_PANDEMIC_MESSAGE = "The 2019/2020 playoffs were cancelled due to the Covid-19 pandemic.";

export const UPPERCASE_KEYS = 1;

export const LOWERCASE_KEYS = 2;

export const VOTING_OPENED_FLAG = 1;

export const VOTING_STARTED_FLAG = 2;

export const VOTING_FINISHED_FLAG = 3;

export const VOTING_CONTEST_ID_3PT = 2;

export const VOTING_CONTEST_ID_DUNK = 3;
